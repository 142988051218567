<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!isStudent" :to="{ path: '/exam' }">
        <app-i18n code="entities.exam.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.exam.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.exam.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-exam-view-toolbar
        v-if="record"
      ></app-exam-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <!-- <app-view-item-text :label="fields.id.label" :value="presenter(record, 'id')"></app-view-item-text> -->

        <app-view-item-text
          :label="fields.name.label"
          :value="presenter(record, 'name')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.examLowerLimit.label"
          :value="presenter(record, 'examLowerLimit')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.examUpperLimit.label"
          :value="presenter(record, 'examUpperLimit')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.status.label"
          :value="presenter(record, 'status')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.duration.label"
          :value="presenter(record, 'duration')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.examType.label"
          :value="presenter(record, 'examType')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.structure.label"
          :permission="fields.structure.readPermission"
          :url="fields.structure.viewUrl"
          :value="presenter(record, 'structure')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.examLanguage.label"
          :permission="fields.examLanguage.readPermission"
          :url="fields.examLanguage.viewUrl"
          :value="presenter(record, 'examLanguage')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.isFree.label"
          :value="presenter(record, 'isFree')"
        ></app-view-item-text>

        <app-view-item-text
                v-if="record.isFree === false"
                :label="fields.price.label"
                :value="presenter(record, 'price')"
        ></app-view-item-text>

        <app-view-item-text v-if="!isStudent"
          :label="fields.fixQuestions.label"
          :value="presenter(record, 'fixQuestions')"
        ></app-view-item-text>

        <app-view-item-text v-if="!isStudent"
                            :label="fields.unScored.label"
                            :value="presenter(record, 'unScored')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.examData.label"
          :value="presenter(record, 'examData')"
        ></app-view-item-text>

        <app-view-item-text v-if="!isStudent"
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text v-if="!isStudent"
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>

        <app-view-item-relation-to-many
          :label="fields.readers.label"
          :permission="fields.readers.readPermission"
          :url="fields.readers.viewUrl"
          :value="presenter(record, 'readers')"
        ></app-view-item-relation-to-many>

        <app-view-item-relation-to-many
          :label="fields.students.label"
          :permission="fields.students.readPermission"
          :url="fields.students.viewUrl"
          :value="presenter(record, 'students')"
        ></app-view-item-relation-to-many>

        <app-view-item-relation-to-many
          :label="fields.questions.label"
          :permission="fields.questions.readPermission"
          :url="fields.questions.viewUrl"
          :value="presenter(record, 'questions')"
          :showIndexes="true"
        ></app-view-item-relation-to-many>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ExamViewToolbar from '@/modules/exam/components/exam-view-toolbar.vue';
import { ExamModel } from '@/modules/exam/exam-model';

const { fields } = ExamModel;

export default {
  name: 'app-exam-view-page',

  props: ['id'],

  components: {
    [ExamViewToolbar.name]: ExamViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'exam/view/record',
      loading: 'exam/view/loading',
      isStudent: 'auth/isStudent',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'exam/view/doFind',
    }),

    presenter(record, fieldName) {
      return ExamModel.presenter(record, fieldName);
    },
  },
};
</script>
